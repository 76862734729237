import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, InputLabel } from '@material-ui/core';

import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router';
import CertificateUnit from '../../communicator/http/Certificate';
import UserUnit from '../../communicator/http/User';
import Camera from '../../elements/camera';
import Popup from '../../elements/popup';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import decodeEudcc from '../../helper/functions/certificateDecoder/decode';
import { PDF_QR_JS, PDF_JS } from 'pdf-qr';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { format_datetime, format_db_datetime } from '../../helper/misc';
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import { useForm, Controller as FormController } from "react-hook-form";

import CameraIcon from '../../assets/icons/camera.png';
import DocumentIcon from '../../assets/icons/upload_document.png';
import Message from '../../elements/message';
import { translate } from '../../helper/functions/multilingual';
import BurgerMenu from '../../elements/burgerMenu';
import { Html5Qrcode } from "html5-qrcode";
import QrcodeDecoder from 'qrcode-decoder';


PDF_JS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF_JS.version}/pdf.worker.min.js`;

registerLocale('de', de);
setDefaultLocale('de');

const Dashboard = () => {
    var qr = new QrcodeDecoder();

    const [loading, setLoading] = useState(true);
    const [certificates, setCertificates] = useState([]);
    const [user, setUser] = useState({});

    const [newCertificateData, setNewCertificateData] = useState({});
    const [certificateSummeryModalOpen, setCertificateSummeryModalOpen] = useState(false);
    const [certificateManuallyModalOpen, setCertificateManuallyModalOpen] = useState(false);

    const [chooseUploadTypePopupOpen, setChooseUploadTypePopupOpen] = useState(false);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [document, setDocument] = useState(null);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");

    const [manuallyType, setManuallyType] = useState(null)

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();

    const qrcodeImageHolderRef = useRef();

    const userUnit = new UserUnit();
    const certificateUnit = new CertificateUnit();

    useEffect(() => {

        loadCertificates();

    }, []);

    useEffect(() => {

        console.log(errors);

    }, [errors]);

    const loadCertificates = () => {

        userUnit.getUserData((_user) => {
            setUser(_user);

            certificateUnit.list((_certificates) => {
                setLoading(false);
                setCertificates(_certificates);
            })
        })



    }

    const translateStatus = (status) => {
        switch (status) {
            case 'valid':
                return "gültig";
            case 'invalid':
                return "ungültig";
            case 'in_review':
                return "in Prüfung";
            case 'rejected':
                return "abgelehnt";
        }
    }

    const translateType = (type) => {
        switch (type) {
            case 'vaccination':
                return "Impfnachweis";
            case 'recovery':
                return "Genesungsnachweis";
            case 'test':
                return "Testnachweis";
        }
    }

    const deleteCertificate = (certificateId) => {

        setLoading(true);

        const cbSuccess = (res) => {
            loadCertificates();
            setLoading(false);

            setErrorMessageTitle(res.title);
            setErrorMessageBody(res.body);
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
        }

        const cbError = (err) => {
            setLoading(false);

            let errorTitle = "Ein unbekannter Fehler ist aufgetreten."
            let errorBody = "Ein Fehler ist aufgetreten."

            if (err !== undefined) {
                errorTitle = err.title;
            }
            if (err !== undefined) {
                errorBody = err.message;
            }

            setErrorMessageTitle(errorTitle);
            setErrorMessageBody(errorBody);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);

        }

        certificateUnit.remove(certificateId, cbSuccess, cbError)

    }

    const chooseCertificateFile = (e) => {

        setLoading(true);
        setChooseUploadTypePopupOpen(false);


        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {

            setDocument(reader.result);


            if (e.target.files[0].type === 'application/pdf') {

                let configs = { // create and populate configs variable
                    scale: {
                        once: true,
                        value: 1
                    },
                    resultOpts: {
                        singleCodeInPage: true
                    },
                    improve: true,
                    jsQR: {}
                };

                // create callback which handles the result 
                const callback = (result) => {
                    if (result.success) {
                        decodeQrCode(result.codes[0]);
                    }
                    else {
                        setCertificateManuallyModalOpen(true);
                    }
                }

                PDF_QR_JS.decodeDocument(e.target, configs, callback);
            } else {
                const imgHolder = qrcodeImageHolderRef.current;
                imgHolder.src = reader.result;

                setTimeout(() => {
                    qr.decodeFromImage(imgHolder).then((res) => {
                        decodeQrCode(res.data);
                        imgHolder.src = '';
                    }).catch(err => {
                        setCertificateManuallyModalOpen(true);
                    })
                }, 200);
            };
            reader.onerror = function (error) {

            };

        }

    }

    const uploadCertificate = () => {

        let expiresAt = new Date(newCertificateData.expiresAt * 1000);
        let issuedAt = new Date(newCertificateData.issuedAt * 1000);

        setCertificateSummeryModalOpen(false);

        let body = {
            type: newCertificateData.type,
            // expiry_date: format_db_datetime(expiresAt),
            date_of_issue: format_db_datetime(issuedAt),
            birthdate: newCertificateData.birthDate
        }

        if (body.type === 'vaccination') {
            body.type_of_vaccination = newCertificateData.additionalData.typeOfVaccination
            body.amount = newCertificateData.additionalData.doses
            body.date_of_issue = format_db_datetime(newCertificateData.additionalData.dateOfVacination)
            body.max = newCertificateData.additionalData.max;
        } else if (body.type === 'recovery') {
            body.date_of_issue = format_db_datetime(newCertificateData.additionalData.firstPositiveTest)
            // body.expiry_date = format_db_datetime(newCertificateData.validUntil)
        } else if (body.type === 'test') {
            body.type_of_test = newCertificateData.additionalData.typeOfTest
            body.date_of_issue = format_db_datetime(newCertificateData.additionalData.testDate)
        }


        const cbSuccess = (res) => {
            setLoading(false);
            loadCertificates();

            setErrorMessageTitle(res.title);
            setErrorMessageBody(res.body);
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);

        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);

        }

        certificateUnit.upload(body, cbSuccess, cbError)
    }

    const uploadRawCertificate = (data) => {

        setCertificateManuallyModalOpen(false);
        setLoading(true);

        let body = {
            type: data.type,
            is_raw: true,
            certificate_file: document
        }

        if (data.type === 'vaccination') {
            body.type_of_vaccination = data.typeOfVaccination;
            body.amount = data.amount;
            // body.max = data.max;
            body.date_of_issue = format_db_datetime(data.issuedAt);
        } else if (data.type === 'recovery') {
            body.date_of_issue = format_db_datetime(data.firstPositiveTest);
            // body.expiry_date = format_db_datetime(data.validUntil);
        } else if (data.type === 'test') {
            body.type_of_test = data.typeOfTest;
            body.date_of_issue = format_db_datetime(data.issuedAt);
        }


        const cbSuccess = (res) => {
            setLoading(false);
            loadCertificates();

            setErrorMessageTitle(res.title);
            setErrorMessageBody(res.body);
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);

        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);

        }


        certificateUnit.upload(body, cbSuccess, cbError)
    }

    const decodeQrCode = (rawQrCode) => {
        decodeEudcc(rawQrCode).then(res => {
            
            console.log("res", res);

            if (res.dateOfBirth !== user.birthdate) {

                setErrorMessageTitle(translate('certificate_birthday_validation_failed'));
                setErrorMessageBody(translate('certificate_birthday_validation_failed_body'));
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);

                setLoading(false);
                return false;
            }

            let additionalData = {};
            let type = '';
            let issuedAt = res.issuedAt;
            if (res.vaccination !== undefined) {
                type = 'vaccination';
                additionalData.vacine = res.vaccination.vaccineProduct + ' - ' + res.vaccination.vaccineManufacturer;
                additionalData.amount = res.vaccination.doseNumber + '/' + res.vaccination.totalDoses;
                additionalData.doses = res.vaccination.doseNumber;
                additionalData.max = res.vaccination.totalDoses;
                additionalData.typeOfVaccination = res.vaccination.ma;
                additionalData.dateOfVacination = res.vaccination.date;
                issuedAt = res.vaccination.date;
            } else if (res.recovery !== undefined) {
                type = 'recovery';
                additionalData.validUntil = res.recovery.validUntil;
                additionalData.firstPositiveTest = res.recovery.firstPositiveTest;
                issuedAt = res.recovery.validFrom;
            } else if (res.test !== undefined) {
                type = 'test';
                additionalData.typeOfTest = res.test.testType;
                additionalData.result = res.test.testResult;
                additionalData.testDate = res.test.testDate;
                issuedAt = res.issuedAt;
            } else {
                return false;
            }

            setNewCertificateData({
                firstName: res.givenName,
                lastName: res.familyName,
                birthDate: res.dateOfBirth,
                issuedAt: issuedAt,
                expiresAt: res.expiresAt,
                type: type,
                additionalData: additionalData,
            });

            setTimeout(() => {
                setLoading(false);
                setCertificateSummeryModalOpen(true);
            }, 400)

            setLoading(false);
        }).catch(err => {
            setLoading(false);
            setCertificateManuallyModalOpen(true);
        })
    }

    const renderAdditionalData = () => {
        switch (newCertificateData.type) {
            case 'test':
                return <>
                    <div className={'label'}>{translate(newCertificateData.additionalData.result)}</div>
                    <div className={'text'}>{translate('result')}</div>
                    <div className={'label'}>{translate(newCertificateData.additionalData.typeOfTest)}</div>
                    <div className={'text'}>{translate('type_of_test')}</div>
                </>
            case 'vaccination':
                return <>
                    <div className={'label'}>{newCertificateData.additionalData.vacine}</div>
                    <div className={'text'}>{translate('vacine')}</div>
                    <div className={'label'}>{newCertificateData.additionalData.amount}</div>
                    <div className={'text'}>{translate('amount_doses')}</div>
                    <div className={'label'}>{format_datetime(newCertificateData.additionalData.dateOfVacination, true)}</div>
                    <div className={'text'}>{translate('date_of_last_vaccination')}</div>
                </>
            case 'recovery':
                return <>
                    {/* <div className={'text'}>{translate('valid_until')}</div>
                    <div className={'label'}>{newCertificateData.additionalData.validUntil}</div> */}
                </>
        }
    }

    return <>
        <img style={{ display: 'none' }} src={''} ref={qrcodeImageHolderRef} />
        <Container>
            <Row className={"paddingTop header"}>
                <Col xs={8}>
                    <h1>{translate('welcome')}, {user.firstName} {user.lastName}</h1>
                </Col>
                <Col xs={4} style={{ textAlign: 'right' }} className="">
                    <BurgerMenu />
                </Col>
            </Row>
        </Container>
        <Container>
            <Row style={{ marginTop: 44 }}>
                <Col md={4}>
                    <div>
                        <div
                            className={`card sameHeight ${user.status}`} style={{}}
                            onClick={() => setChooseUploadTypePopupOpen(true)}
                        >
                            <div className="content">
                                <div>
                                    <div className="cardHeadline">{translateStatus(user.status)}</div>
                                    <div className="">{translate('your_3g_status')}</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div
                            className="button fixedOnMobile"
                            style={{ width: '100%' }}
                            onClick={() => setChooseUploadTypePopupOpen(true)}
                        >
                            Neuen Nachweis hochladen
                        </div>
                    </div>
                    <img id="reader" />
                </Col>
                <Col md={8}>
                    <Table className="table_container">
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('uploaded')}</TableCell>
                                <TableCell>{translate('valid_until')} *</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{translate('type_of_certificate')} *</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                certificates.map((certificate, key) => {

                                    let tooltipItems = [];

                                    tooltipItems.push({
                                        label: translate('delete'),
                                        do: () => { deleteCertificate(certificate.id) },
                                    })

                                    return <TableRow key={key}>
                                        <TableCell>
                                            <div className="mobile_label">{translate('uploaded')}</div>
                                            {format_datetime(certificate.uploadedAt)}
                                        </TableCell>
                                        <TableCell>
                                            {certificate.expiryDate !== false && <div className="mobile_label">{translate('valid_until')} *</div>}
                                            {certificate.expiryDate !== false && format_datetime(certificate.expiryDate, certificate.type !== 'test')}
                                        </TableCell>
                                        <TableCell className='noPaddingColumn'>
                                            <div className={`statusBadge ${certificate.status}`}>{translate(certificate.status)}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="mobile_label">{translate('type_of_certificate')} *</div>
                                            {translate(certificate.type + '_certificate')}
                                        </TableCell>
                                        <TableCell className='noPaddingColumn'>
                                            {tooltipItems.length > 0 && <ToolTip className='listTooltip' items={tooltipItems} />}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <div className="small" style={{ marginTop: 20 }}>* {translate('this_info_is_only_visible_to_you')}</div>
                </Col>
            </Row>
        </Container>

        <Container className="footer" fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <a target="_blank" href="https://3garbeitsplatz.com/datenschutz">{translate('gdpr')}</a>
                    <a target="_blank" href="https://3garbeitsplatz.com/impressum">{translate('imprint')}</a>
                </Col>
            </Row>
        </Container>
        <div class="hide_desktop" style={{ width: '100%', height: 100 }}></div>

        <Popup
            close={() => setChooseUploadTypePopupOpen(false)}
            open={chooseUploadTypePopupOpen}>
            <h1>{translate('how_you_want_to_upload')}</h1>
            <Container fluid>
                <Row style={{ marginTop: 44 }}>
                    <Col md={2}>

                    </Col>
                    <Col md={4}>
                        <div
                            className="card"
                            style={{ textAlign: 'center', alignItems: 'center' }}
                            onClick={() => {
                                setChooseUploadTypePopupOpen(false);
                                setCameraOpen(true)
                            }}
                        >
                            <img src={CameraIcon} style={{ width: '70%' }} />
                            {translate('use_camera')}
                        </div>
                    </Col>
                    <Col md={4}>

                        <label for="certificateFileInput" className="card"
                            style={{ textAlign: 'center', alignItems: 'center' }}
                        >
                            <img src={DocumentIcon} style={{ width: '70%' }} />
                            {translate('upload_pdf')}
                        </label>
                        <input
                            type="file" style={{ display: 'none' }}
                            accept=".pdf,.jpg,.png,.jpeg"
                            name="certificateFileInput" id="certificateFileInput"
                            onChange={chooseCertificateFile}
                        />
                    </Col>
                    <Col md={2}>

                    </Col>
                </Row>
            </Container>
        </Popup>

        <Popup
            close={() => setCameraOpen(false)}
            style={{
                padding: 0,
                height: '70vh',
                overflow: 'hidden',
                maxWidth: 500
            }}
            open={cameraOpen}>
            <h4 style={{
                marginTop: 20,
                marginRight: 60,
                marginLeft: 20
            }}>{translate('please_hold_pdf_into_camera')}</h4>
            <div style={{ marginTop: 44, height: 'calc(70vh - 84px)' }}>
                <BarcodeScannerComponent
                    delay={500}
                    style={{
                        width: '100%'
                    }}
                    width={'100%'}
                    height={'100%'}
                    onUpdate={(err, result) => {
                        if (result) {
                            setCameraOpen(false);
                            setLoading(true);
                            decodeQrCode(result.text);
                        }
                    }}
                    facingMode={'rear'}
                    stopStream={!cameraOpen}
                />
            </div>
        </Popup>

        <Popup
            close={() => setCertificateSummeryModalOpen(false)}
            style={{
            }}
            open={certificateSummeryModalOpen}>

            <h3>{translate('your')} {translateType(newCertificateData.type)}</h3>
            <div className={"label"}>{newCertificateData.lastName}</div>
            <div className={"text"}>{translate('lastname')}</div>

            <div className={"label"}>{newCertificateData.firstName}</div>
            <div className={"text"}>{translate('firstname')}</div>
            {
                renderAdditionalData()
            }
            <div
                className="button" style={{ width: '100%', marginTop: 30 }}
                onClick={uploadCertificate}
            >{translate('choose_and_upload')}</div>
        </Popup>

        <Popup
            close={() => setCertificateManuallyModalOpen(false)}
            open={certificateManuallyModalOpen}
        >

            <h4>{translate('no_qr_code_found_text_1')}</h4>
            <div>{translate('no_qr_code_found_text_2')}</div>
            <div>{translate('no_qr_code_found_text_3')}</div>

            <FormControl
                className="selectFormControl"
                variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="type">{translate('type_of_certificate')}</InputLabel>
                <Select
                    label={translate('type_of_certificate')}
                    {...register('type', { required: true })}
                    type={'textinput'}
                    onChange={(val) => {
                        setManuallyType(val.target.value);
                    }}
                    error={errors.type !== undefined}
                >
                    <MenuItem value={"test"}>{translate('test')}</MenuItem>
                    <MenuItem value={"vaccination"}>{translate('vaccination')}</MenuItem>
                    <MenuItem value={"recovery"}>{translate('recovery')}</MenuItem>
                </Select>
            </FormControl>
            {
                manuallyType === 'vaccination' &&
                <form onSubmit={handleSubmit(uploadRawCertificate)}>

                    <FormControl
                        variant="standard" sx={{ m: 1, minWidth: 120 }}
                        className="selectFormControl"
                        error={errors.typeOfVaccination}
                    >
                        <InputLabel id="type">{translate('type_of_vaccination')}</InputLabel>
                        <Select
                            {...register('typeOfVaccination', { required: true })}
                            label={translate('type_of_vaccination')}
                        >
                            <MenuItem value={"ORG-100001699"}>AstraZeneca AB</MenuItem>
                            <MenuItem value={"ORG-100030215"}>Biontech Manufacturing GmbH</MenuItem>
                            <MenuItem value={"ORG-100001417"}>Janssen-Cilag International</MenuItem>
                            <MenuItem value={"ORG-100031184"}>Moderna Biotech Spain S.L.</MenuItem>
                            <MenuItem value={"ORG-100006270"}>Curevac AG</MenuItem>
                            <MenuItem value={"ORG-100013793"}>CanSino Biologics</MenuItem>
                            <MenuItem value={"ORG-100020693"}>China Sinopharm International Corp. - Beijing location</MenuItem>
                            <MenuItem value={"ORG-100010771"}>Sinopharm Weiqida Europe Pharmaceutical s.r.o. - Prague location</MenuItem>
                            <MenuItem value={"ORG-100024420"}>Sinopharm Zhijun (Shenzhen) Pharmaceutical Co. Ltd. - Shenzhen location</MenuItem>
                            <MenuItem value={"ORG-100032020"}>Novavax CZ AS</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        className="selectFormControl"
                        error={errors.amount}
                        variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="type">{translate('vaccination_amount')}</InputLabel>
                        <Select
                            {...register('amount', { required: true })}
                            label={translate('vaccination_amount')}
                        >
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                            <MenuItem value={"3"}>3</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <p
                        className={`inputLabel ${errors.issuedAt ? 'error' : undefined}`}
                    >{translate('date_of_vaccination')}</p>
                    <FormController
                        name={"issuedAt"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('issuedAt', { required: true })}
                                name="issuedAt"
                                selected={value}
                                dateFormat="dd.MM.yyyy"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                maximumDate={new Date()}
                            />
                        )}
                    />
                    <input type="submit"
                        className="button" style={{ width: '100%', marginTop: 30 }}
                        value={translate('upload_certificate')}
                    />

                </form>
            }
            {
                manuallyType === 'recovery' &&
                <form onSubmit={handleSubmit(uploadRawCertificate)}>

                    <p
                        className={`inputLabel ${errors.firstPositiveTest ? 'error' : undefined}`}
                    >{translate('first_positiv_test')}</p>
                    <FormController
                        name={"firstPositiveTest"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('firstPositiveTest', { required: true })}
                                name="firstPositiveTest"
                                selected={value}
                                dateFormat="dd.MM.yyyy"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                maxDate={new Date()}
                            />
                        )}
                    />

                    <br />
                    <br />
                    {/* <p
                        className={`inputLabel ${errors.validUntil ? 'error' : undefined}`}
                    >{translate('valid_until')}</p>
                    <FormController
                        name={"validUntil"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('validUntil', { required: true })}
                                name="validUntil"
                                selected={value}
                                dateFormat="dd.MM.yyyy"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                minDate={new Date()}
                            />
                        )}
                    /> */}

                    <input type="submit"
                        className="button" style={{ width: '100%', marginTop: 30 }}
                        value={translate('upload_certificate')}
                    />

                </form>
            }

            {
                manuallyType === 'test' &&
                <form onSubmit={handleSubmit(uploadRawCertificate)}>
                    <FormControl
                        className="selectFormControl"
                        error={errors.typeOfTest}
                        variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="type">{translate('type_of_test')}</InputLabel>
                        <Select
                            {...register('typeOfTest', { required: true })}
                            label={translate('type_of_test')}
                            error={errors.typeOfTest}
                        >
                            <MenuItem value={"pcr"}>{translate('pcr')}</MenuItem>
                            <MenuItem value={"antigen"}>{translate('antigen')}</MenuItem>
                        </Select>
                    </FormControl>

                    <br />
                    <br />
                    <p
                        className={`inputLabel ${errors.issuedAt ? 'error' : undefined}`}
                    >{translate('date_of_sign_test')}</p>
                    <FormController
                        name={"issuedAt"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('issuedAt', { required: true })}
                                name="issuedAt"
                                selected={value}
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="dd.MM.yyyy hh:mm"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                maxDate={new Date()}
                            />
                        )}
                    />


                    <input type="submit"
                        className="button" style={{ width: '100%', marginTop: 30 }}
                        value={translate('upload_certificate')}
                    />
                </form>
            }

        </Popup>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
        <Loading visible={loading} />
    </>

}

export default Dashboard;