import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class CertificateUnit extends Unit {

    constructor() {
        super();
    }

    upload = (body, cbSuccess, cbError) => {
        this.post('/certificate', body, cbSuccess, cbError);
    }

    list = (cbSuccess, cbError) => {
        this.get('/certificate', cbSuccess, cbError);
    }

    remove = (certificateId, cbSuccess, cbError) => {

        let body = {
            id: certificateId
        }

        this.delete('/certificate', body, cbSuccess, cbError);

    }
}



