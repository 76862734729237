import { FormControl, InputLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import UserUnit from '../../../communicator/http/User';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';
import { useForm, Controller as FormController } from "react-hook-form";
import Message from '../../../elements/message';
import { translate } from '../../../helper/functions/multilingual';
import { Link } from 'react-router-dom';
import Popup from '../../../elements/popup';

const AcceptGdpr = ({ doAcceptGdpr }) => {

    return (
        <>
            <div className="login_wrapper">
                <div className="login_container"
                    style={{
                        maxHeight: '90vh',
                        width: '100%',
                        maxWidth: 900,
                        height: 1000,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <h2 style={{}}>{translate('accept_gdpr')}</h2>
                    <br/>
                    <iframe style={{ height: 800 }} src='https://www.3garbeitsplatz.com/datenschutz-app/'></iframe>
                    <br/>
                    <div
                        onClick={doAcceptGdpr}
                        style={{ width: '100%' }}
                        className="button"
                    >{translate('accept_gdpr')}</div>
                </div>
            </div>
        </>
    );
}

export default AcceptGdpr;