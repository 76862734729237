import { FormControl, InputLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import UserUnit from '../../communicator/http/User';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import { useForm, Controller as FormController } from "react-hook-form";
import Message from '../../elements/message';
import { translate } from '../../helper/functions/multilingual';
import { Link } from 'react-router-dom';
import Popup from '../../elements/popup';
import AcceptGdpr from './gdpr';
import ChangePassword from './changePassword';

const Login = ({ startPage }) => {

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();
    const { register: registerChangePassword, handleSubmit: handleSubmitChangePassword, formState: { errors: errorsChangePassword } } = useForm();

    let userUnit = new UserUnit();

    console.log("startPage", startPage);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");

    const [currentScreen, setCurrentScreen] = useState(startPage);

    const doLogin = (data) => {

        setLoading(true);

        let cbSuccess = (res) => {

            localStorage.setItem('token', res.token);

            if (res.acceptedGdpr && res.changedInitPassword) {
                window.location.reload();
            } else {
                setLoading(false);

                if (!res.acceptedGdpr) {
                    setCurrentScreen('gdpr');
                } else {
                    setCurrentScreen('changePassword');
                }

            }
        }

        let cbError = (res) => {
            setLoading(false);

            setErrorMessageTitle(translate('login_error'));
            setErrorMessageBody(translate('login_error_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }

        userUnit.login(data.email, data.password, cbSuccess, cbError);

    }

    const doAcceptGdpr = () => {

        setLoading(true);

        const cbSuccess = (res) => {
            setLoading(false);
            setCurrentScreen('changePassword');
        }

        const cbError = (err) => {
            console.log(err);
            setErrorMessageTitle(translate('500_title'));
            setErrorMessageBody(translate('500_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }

        userUnit.acceptGdpr(cbSuccess, cbError);
    }

    const doChangePassword = (data) => {
        setLoading(false);

        const cbSuccess = (res) => {
            window.location.reload();
        }

        const cbError = (err) => {
            console.log(err);
            setErrorMessageTitle(translate('500_title'));
            setErrorMessageBody(translate('500_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }

        userUnit.update({ password: data.password }, cbSuccess, cbError);
    }

    if (currentScreen === 'gdpr') {
        return <AcceptGdpr
            doAcceptGdpr={doAcceptGdpr}
        />;
    }
    if (currentScreen === 'changePassword') {
        return <ChangePassword
            doChangePassword={doChangePassword}
        />;
    }

    return (
        <>
            <div className="login_wrapper">
                <div className="login_container">
                    <h1>{translate('signin')}</h1>
                    <br />
                    <form onSubmit={handleSubmit(doLogin)}>
                        <TextField
                            {...register('email', { required: true })}
                            error={errors.email}
                            id="standard-basic"
                            label={translate('email')}
                            type="email"
                        />
                        <TextField
                            {...register('password', { required: true })}
                            error={errors.password}
                            id="standard-basic"
                            label={translate('password')}
                            type="password"
                        />
                        <input type="submit" style={{ width: '100%' }} className="button" value={translate('signin')} />
                    </form>
                    <br />
                    <Link to="/forgot-password" style={{ marginTop: 10 }}>{translate('forgot_password')}</Link>
                </div>

            </div>
            <Loading container_style={{ zIndex: 9 }} visible={loading} />

            {/* <Popup
                close={() => setShowGdprPopup(false)}
                style={{
                    maxWidth: 500,
                    maxHeight: '90vh',
                    height: 800,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                open={showGdprPopup}>
                <h4 style={{}}>{translate('accept_gdpr')}</h4>
                <iframe style={{ height: 800 }} src='https://www.3garbeitsplatz.com/datenschutz-app/'></iframe>
                <div
                    onClick={doAcceptGdpr}
                    style={{ width: '100%' }}
                    className="button"
                >{translate('accept_gdpr')}</div>
            </Popup>
            <Popup
                close={() => setShowChangePasswordPopup(false)}
                style={{
                    maxWidth: 500
                }}
                open={showChangePasswordPopup}>
                <h4 style={{}}>{translate('please_change_password')}</h4>
                <form onSubmit={handleSubmitChangePassword(doChangePassword)}>
                    <TextField
                        {...registerChangePassword('password', { required: true })}
                        error={errorsChangePassword.password}
                        id="standard-basic"
                        label={translate('password')}
                        type="password"
                    />
                    <input type="submit" style={{ width: '100%' }} className="button" value={translate('change_password')} />
                </form>
            </Popup> */}
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />
        </>
    );
}

export default Login;