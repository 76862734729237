import React, { Component, useState, useEffect } from 'react';
import styles from './style.module.scss';

const Message = ({ title, open, body, type, buttonText, buttonAction }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (open) {
            setIsOpen(true);
            setShow(true);
        } else {
            setShow(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 200);
        }
    }, [open]);

    if (!isOpen) {
        return null;
    }

    return <><div className={`${styles.overlay} ${show ? styles.show : undefined}`}>
        <div className={styles.container}>
            <h3 className={`${type === 'error' ? styles.error : undefined}`}>{title}</h3>
            {body !== undefined && body !== '' && <div className={styles.text}>{body}</div>}
            <div
                className={`button ${styles.button} ${type === 'error' ? styles.error : undefined}`}
                onClick={buttonAction}
            >
                {buttonText}
            </div>
        </div>
    </div>
    </>

}

export default Message;