import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class UserUnit extends Unit {

    constructor() {
        super();
    }

    login = (email, password, cbSuccess, cbError) => {

        let body = {
            email: email,
            password: password,
            is_admin: true,
        }

        this.post('/signin', body, cbSuccess, cbError);

    }

    acceptGdpr = (cbSuccess, cbError) => {
        this.post('/acceptgdpr', {}, cbSuccess, cbError);
    }


    validateToken = (cbSuccess, cbError) => {

        let body = {
        }

        this.post('/validatetoken', body, cbSuccess, cbError);

    }

    getPermissions = (cbSuccess, cbError) => {
        this.get('/permission', cbSuccess, cbError);
    }

    update = (body, cbSuccess, cbError) => {

        body.token = getUserToken();

        this.put('/users', body, cbSuccess, cbError);

    }

    getUserData = (cbSuccess, cbError) => {
        this.get('/users', cbSuccess, cbError);
    }

    forgotPassword = (email, cbSuccess, cbError) => {
        this.post(`/lostpassword`, { email: email }, cbSuccess, cbError);
    }

    resetPassword = (password, key, secret, cbSuccess, cbError) => {
        this.post(`/resetpassword`, {
            password: password,
            key: key,
            secret: secret
        }, cbSuccess, cbError);
    }

}



