import { FormControl, InputLabel, TextField } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import UserUnit from '../../../communicator/http/User';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';
import { useForm, Controller as FormController } from "react-hook-form";
import Message from '../../../elements/message';
import { translate } from '../../../helper/functions/multilingual';
import { Link } from 'react-router-dom';
import Popup from '../../../elements/popup';

const ChangePassword = ({ doChangePassword }) => {

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();

    const password = useRef({});
    password.current = watch("password", "");

    return (
        <>
            <div className="login_wrapper">
                <div className="login_container"
                >
                    <h2 style={{}}>{translate('please_change_password')}</h2>
                    <form onSubmit={handleSubmit(doChangePassword)}>
                        <TextField
                            {...register('password', {
                                required: true,
                                minLength: {
                                    value: 8,
                                    message: "Passwort muss mindestens 8-Stellig sein"
                                }
                            })}
                            error={errors.password}
                            id="standard-basic"
                            label={translate('password')}
                            type="password"
                        />
                        {errors.password && <p>{errors.password.message}</p>}
                        <TextField
                            {...register('passwordRepeat', {
                                validate: value =>
                                    value === password.current || "Die Passwörter stimmen nicht überein"
                            })}
                            error={errors.passwordRepeat}
                            id="standard-basic"
                            label={translate('password_repeat')}
                            type="password"
                        />
                        {errors.passwordRepeat && <p>{errors.passwordRepeat.message}</p>}
                        <input type="submit" style={{ width: '100%' }} className="button" value={translate('change_password')} />
                    </form>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;